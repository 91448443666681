  import _ from "lodash";
  import React, { useState, useEffect, useRef } from 'react';
  import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
  import { createThread, createMessage, createRun, listMessages, getRun, submitToolOutput } from './openai.util';
  import { getApplicant, upsertApplicant, resetPasswordRequest } from "./aws_backend.util";
  import { Input, Button, Form, List, Modal, message as antMessage } from 'antd';
  import './App.css'; // Custom CSS for additional styling
  import logo from './logo512.png'; // Adjust the path based on your folder structure
  import Loading from './Loading';
  import ReactMarkdown from 'react-markdown';
  import ResetPassword from './components/ResetPassword'; // Import your new component

  const { TextArea } = Input;

  function sleep(ms = 0) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  function App() {
    const [message, setMessage] = useState('');
    const [threadId, setThreadId] = useState(null);
    const [responses, setResponses] = useState([]);
    const [errorMessage, setErrorMessage] = useState(null);
    const [applicantName, setApplicantName] = useState(null);
    const [applicant, setApplicant] = useState(null);
    const [loading, setLoading] = useState(true);
    const [enterToSend, setEnterToSend] = useState(true);
    const [showInfoModal, setShowInfoModal] = useState(false);
    const listContainerRef = useRef(null);

    useEffect(() => {
      scrollToBottom();
    }, []);

    useEffect(() => {
      const createMainThread = async () => {
        try {
          const thread = await createThread();
          setThreadId(thread.id);
          showInfoWarning();
          setLoading(false);
        } catch (error) {
          console.error('Error in creating thread:', error);
        }
      };

      createMainThread();
    }, []);

    const scrollToBottom = async () => {
      if (listContainerRef.current) {
        listContainerRef.current.scrollTop = listContainerRef.current.scrollHeight;
      }
    };

    const showInfoWarning = () => {
      setShowInfoModal(true);
    };  

    const handleMessageSubmit = async (e) => {
      e.preventDefault();
      if(loading){
        return;
      }
      setLoading(true);
      if (!threadId) {
        console.error('Thread ID is not set');
        return;
      }

      try {
        await createMessage(threadId, message);
        setMessage(null);
        const messages = await listMessages(threadId);
        setResponses(_.reverse(messages.data));
        await scrollToBottom();

        const run = await createRun(threadId);

        let runResult = await getRun(threadId, run.id);
        while(runResult.status != "completed" && runResult.status != "failed"){
          if(runResult.status == "requires_action"){
            if(runResult.required_action.type == "submit_tool_outputs"){
              runResult.required_action.submit_tool_outputs.tool_calls.forEach(async call => {
                if(call.type == "function"){
                  let new_data = {"error" : "no_response"}
                  switch (call.function.name) {
                    case "createOrUpdateApplicant": {
                      try{
                        runResult.status = "function_called";
                        const data = JSON.parse(call.function.arguments);
                        new_data = await upsertApplicant(threadId, data);    
                        runResult = await getRun(threadId, run.id);
                      }
                      catch(error){
                        console.error('Error in handling message:', error);
                        setErrorMessage(JSON.stringify(error));
                        new_data = {"error" : "no_response"}
                        runResult.status = "local_error";
                        break;
                      }
                      finally {
                        while(runResult.status == "in_progress" || runResult.status == "queued") {
                          await sleep(10000);
                          runResult = await getRun(threadId, run.id);   
                        }
                        if(runResult.status == "requires_action"){
                          await submitToolOutput(threadId, run.id, call.id, new_data);  
                        } 
                      }
                    }
                    case "setApplicantName":{
                      try{
                        runResult.status = "function_called";
                        const args = JSON.parse(call.function.arguments);
                        setApplicantName(args.name);  
                      }
                      catch(error){
                        console.error('Error in handling message:', error);
                        setErrorMessage(JSON.stringify(error));
                        runResult.status = "local_error";
                        break;
                      }
                    }
                    case "resetApplicantPasswordByEmail":{
                      try{
                        runResult.status = "function_called";
                        const args = JSON.parse(call.function.arguments);
                        new_data = await resetPasswordRequest(args.email);
                        runResult = await getRun(threadId, run.id);
                      }
                      catch(error){
                        console.error('Error in handling message:', error);
                        setErrorMessage(JSON.stringify(error));
                        runResult.status = "local_error";
                        break;
                      }
                      finally {
                        while(runResult.status == "in_progress" || runResult.status == "queued") {
                          await sleep(10000);
                          runResult = await getRun(threadId, run.id);   
                        }
                        if(runResult.status == "requires_action"){
                          await submitToolOutput(threadId, run.id, call.id, new_data);  
                        } 
                      }
                    }
                    case "getApplicantByEmail":{
                      try{
                        runResult.status = "function_called";
                        const args = JSON.parse(call.function.arguments);
                        new_data = await getApplicant(args.email);
                        setApplicant(new_data);
                        setApplicantName(new_data.name);      
                        runResult = await getRun(threadId, run.id);
                      }
                      catch(error){
                        console.error('Error in handling message:', error);
                        setErrorMessage(JSON.stringify(error));
                        runResult.status = "local_error";
                        break;
                      }
                      finally {
                        while(runResult.status == "in_progress" || runResult.status == "queued") {
                          await sleep(10000);
                          runResult = await getRun(threadId, run.id);   
                        }
                        if(runResult.status == "requires_action"){
                          await submitToolOutput(threadId, run.id, call.id, new_data);  
                        } 
                      }
                    }
                  }
                }
              });
            }
            else{
              break;
            }
          }
          else {
            await sleep(10000);
            if(runResult.status != "local_error") {
              runResult = await getRun(threadId, run.id);
            }
            else{
              break;
            }
          }
        }
      } catch (error) {
        console.error('Error in handling message');
      }
      finally {
        const messages = await listMessages(threadId);
        setResponses(_.reverse(messages.data));
        scrollToBottom();
        setLoading(false);
      }
    };

    return (
      <Router>
        <div className="App dark-theme">
        <Routes>
            <Route exact path="/" element= {
              <>
                {loading && <Loading />}
                <div className="app-header">
                  <img src={logo} alt="Global Interviewer Logo" className="app-logo"/>
                  <div>
                    <h1>Global Interviewer</h1>
                  </div>
                </div>
                <div>
                  <p className="app-slogan">Connecting Talents with Opportunities, Globally!</p>
                </div>
                <div className="chat-container">
                  <List
                    className="message-list"
                    dataSource={responses}
                    ref={listContainerRef}
                    locale={{ emptyText: 'Start the conversation with Ivy your interviewer. Introduce your self and give a short description of who you are and what you like to do.' }}
                    renderItem={item => (
                      <List.Item key={item.id} className="message-item">
                        <div>
                          <div className="chat-name">
                            {item.role == "user" ? applicantName ? applicantName : "Applicant" : "Ivy"}
                          </div>
                          <div className="chat-message">
                            <ReactMarkdown>{item.content.filter(m => m.type === 'text').map(m => m.text.value).join("\n")}</ReactMarkdown>
                          </div>
                        </div>
                      </List.Item>
                    )}
                  />
                </div>
                <form onSubmit={handleMessageSubmit} className="input-form">
                <TextArea
                  type="text"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  placeholder="Enter your message..."
                  className="input-field"
                  onKeyPress={(e) => {
                    if (enterToSend && e.key === 'Enter' && !e.shiftKey) {
                      e.preventDefault();
                      handleMessageSubmit(e);
                    }
                  }}
                />
                <div className="submit-container">
                  <button type="submit" className="submit-button">Send</button>              
                  <div className="toggle-container">
                    <div
                      className={`toggle-checkbox ${enterToSend ? 'checked' : ''}`}
                      onClick={() => setEnterToSend(!enterToSend)}
                    />
                    <div>Enter is {enterToSend ? "Send" : "New Line"}</div>                  
                  </div>
                </div>
                </form>
                <Modal
                title="Information Warning"
                className="popi-modal"
                visible={showInfoModal}
                onOk={() => setShowInfoModal(false)}
                onCancel={() => setShowInfoModal(false)}
                cancelButtonProps={{visible: "false"}}
                footer={[
                  <Button key="ok" type="primary" onClick={() => setShowInfoModal(false)}>
                    I Accept
                  </Button>,
                ]}
                >
                <p>
                All information collected during this interview is subject to the Protection of Personal Information Act (POPI) and other applicable data protection regulations. We want to inform you that any data you provide as part of this interview process will be treated with the utmost care and security.
                <br/><br/>
                Your personal information, such as your name, contact details, and any other data shared during this interview, may be accessible to authorized personnel involved in the hiring process. This information will only be used for the purpose of evaluating your qualifications and potential fit for work opportunities.
                <br/><br/>
                Rest assured that we are committed to protecting your privacy. Your data will not be shared with third parties without your explicit consent. We will retain your information only for the duration necessary to assess your application and, if applicable, for future employment considerations.
                <br/><br/>
                By continuing with this interview, you acknowledge that your information may be used for employment-related purposes and that you have read and understood our privacy policy. If you have any concerns about the handling of your data, please do not hesitate to contact us.
                </p>
                </Modal>
                <Modal
                title="Error"
                className="popi-modal"
                visible={errorMessage}
                onOk={() => setErrorMessage(null)}
                onCancel={() => setErrorMessage(null)}
                cancelButtonProps={{visible: "false"}}
                footer={[
                  <Button key="ok" type="primary" onClick={() => setErrorMessage(null)}>
                    Close
                  </Button>,
                ]}
                >
                  <div className="error-message">{errorMessage}</div>
                </Modal>
              </>
            }/>
            <Route path="/password_reset" element={<ResetPassword />} />
          </Routes>
        </div>
      </Router>
    );
  }

  export default App;
