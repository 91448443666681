import axios from 'axios';

const apiClient = axios.create({
  baseURL: 'https://g08krx85a0.execute-api.us-east-1.amazonaws.com'
});

// Function to perform a GET request to the applicant by email
export const getApplicant = async (email) => {
  try {
    const response = await apiClient.get(`/applicant?email=${email}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching applicant:', error);    
    return {};
  }
};

// Function to create a new applicant
export const upsertApplicant = async (threadId, data) => {
    try {
      const response = await apiClient.post(`/applicant`, JSON.stringify(data));
      return response.data; 
    } catch (error) {
      console.error(`Error creating message in thread ${threadId}:`, error);
      return {};
    }
  };


  
  // Function to reset the password of an applicant
  export const resetPasswordRequest = async (email) => {
    try {
      const response = await apiClient.get(`/applicant/reset_password?email=${email}`);
      return response.data; 
    } catch (error) {
      console.error(`Error requesting password reset for ${email}:`, error);
      return {};
    }
  };
  // Function to reset the password of an applicant
  export const resetPassword = async (data) => {
      const response = await apiClient.post(`/applicant/reset_password`, JSON.stringify(data));
      return response.data; 
  };