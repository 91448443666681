import axios from 'axios';

const assistantId = "asst_iJLNjDlrlJxQWdwYevpeQrHt";

const apiClient = axios.create({
  baseURL: 'https://api.openai.com/v1', // Replace with your actual base URL
  headers: {
    'Content-Type': 'application/json',
    'OpenAI-Beta': 'assistants=v1',
    'Authorization': 'Bearer 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', // You might want to set this dynamically
    'OpenAI-Organization': 'org-HHcdKmW4XeJriBPQSXag2V5P', // You might want to set this dynamically
  }
});

// Function to set the Authorization and OpenAI-Organization headers dynamically
export const setAuthHeaders = (apiKey, orgId) => {
  apiClient.defaults.headers['Authorization'] = apiKey;
  apiClient.defaults.headers['OpenAI-Organization'] = orgId;
};

// Function to perform a GET request to the models endpoint
export const getModels = async () => {
  try {
    const response = await apiClient.get('/models');
    return response.data;
  } catch (error) {
    console.error('Error fetching models:', error);
    throw error;
  }
};

// Function to perform a GET request to a specific thread endpoint
export const getThread = async (threadId) => {
    try {
    const response = await apiClient.get(`/threads/${threadId}`);
    return response.data;
    } catch (error) {
    console.error(`Error fetching thread ${threadId}:`, error);
    throw error;
    }
};

// Function to create a new thread with a POST request
export const createThread = async () => {
    try {
    const response = await apiClient.post('/threads');
    return response.data;
    } catch (error) {
    console.error('Error creating thread:', error);
    throw error;
    }
};

// Function to create a new message in a specific thread
export const createMessage = async (threadId, message) => {
    try {
      const response = await apiClient.post(`/threads/${threadId}/messages`, {
        role: "user",
        content: message
      });
      return response.data;
    } catch (error) {
      console.error(`Error creating message in thread ${threadId}:`, error);
      throw error;
    }
  };

  // Function to create a new run in a specific thread
export const createRun = async (threadId) => {
    try {
    const response = await apiClient.post(`/threads/${threadId}/runs`, {
        "assistant_id": assistantId
    });
    return response.data;
    } catch (error) {
    console.error(`Error creating run ${threadId}:`, error);
    throw error;
    }
};

// Function to create a new run in a specific thread
export const getRun = async (threadId, runId) => {
  try {
  const response = await apiClient.get(`/threads/${threadId}/runs/${runId}`);
  return response.data;
  } catch (error) {
  console.error(`Error creating run ${threadId}:`, error);
  throw error;
  }
};


// Function to perform a GET request to a specific thread endpoint
export const listMessages = async (threadId) => {
    try {
    const response = await apiClient.get(`/threads/${threadId}/messages`);
    return response.data;
    } catch (error) {
    console.error(`Error fetching message list ${threadId}:`, error);
    throw error;
    }
};

// Function to submit a tool action
export const submitToolOutput = async (threadId, runId, toolCallId, data) => {
  try {
  const response = await apiClient.post(`/threads/${threadId}/runs/${runId}/submit_tool_outputs`, {
    "tool_outputs": [
        {
            "tool_call_id": toolCallId,
            "output": JSON.stringify(data)
        }
    ]
  });
  return response.data;
  } catch (error) {
  console.error(`Error submiting tool output ${toolCallId}:`, error);
  throw error;
  }
};